import { auth } from "@/firebase"
import Landing from "@/views/Landing"
import Vue from "vue"
import VueMeta from "vue-meta"
import VueRouter from "vue-router"

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  { path: "*", redirect: "/" },
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      appbar: true,
      // title: "Qizto - User Engagement Platform to make Quizzes & Polls | Free Online Quiz Maker",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      appbar: true,
      // title: "Qizto - Login",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import(/* webpackChunkName: "signup" */ "../views/Signup.vue"),
    meta: {
      appbar: true,
      // title: "Qizto - Signup",
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import(/* webpackChunkName: "forgotpassword" */ "../views/ForgotPassword.vue"),
    meta: {
      appbar: true,
      title: "Qizto - Forgot Password",
    },
  },
  {
    path: "/join",
    name: "Join",
    component: () => import(/* webpackChunkName: "join" */ "../views/Join.vue"),
    meta: {
      // title: "Qizto - Join",
      appbar: true,
    },
    props: true,
  },
  {
    path: "/dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      title: "Qizto - My Quizzes",
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Quizzes",
        component: () => import(/* webpackChunkName: "quizzes" */ "../views/Quizzes.vue"),
        meta: {
          title: "Qizto - My Quizzes",
        },
      },
      {
        path: "/guestsignup",
        name: "GuestSignUp",
        component: () => import(/* webpackChunkName: "anonymous" */ "../views/AnonymsUser.vue"),
        meta: {
          title: "Qizto - Guest Sign Up",
        },
        props: true,
      },
      {
        path: "/create/:type",
        name: "Create",
        component: () => import(/* webpackChunkName: "create" */ "../views/Create.vue"),
        meta: {
          title: "Qizto - Create Quiz",
        },
        props: true,
      },
      {
        path: "/quizzes/:id",
        name: "EditQuiz",
        component: () => import(/* webpackChunkName: "editquiz" */ "../views/EditQuiz.vue"),
        props: true,
        meta: {
          title: "Qizto - Edit Quiz",
        },
      },
      {
        path: "/sessions/:id",
        name: "sessions",
        component: () => import(/* webpackChunkName: "sessionlist" */ "../views/SessionList.vue"),
        props: true,
        meta: {
          title: "Qizto - Sessions List",
        },
      },
      {
        path: "/sessions/:quizid/:id",
        name: "session-data",
        component: () => import(/* webpackChunkName: "sessiondata" */ "../views/SessionData.vue"),
        props: true,
        meta: {
          title: "Qizto - Session Data",
        },
      },
      {
        path: "/answerdetails/:id",
        name: "answer-details",
        component: () => import(/* webpackChunkName: "answersdetails" */ "../views/AnswersDetails.vue"),
        props: true,
        meta: {
          title: "Qizto - Answer Sheet",
        },
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
        meta: {
          title: "Qizto - My Profile",
        },
      },
      {
        path: "/presenter/:id",
        name: "Presenter",
        component: () => import(/* webpackChunkName: "presenter" */ "../views/Presenter.vue"),
        props: true,
        meta: {
          title: "Qizto - Start Quiz",
          hidenavbar: true,
        },
      },
    ],
  },
  {
    path: "/participant/:id",
    name: "Participant",
    component: () => import(/* webpackChunkName: "participant" */ "../views/Participant.vue"),
    props: true,
    meta: {
      title: "Qizto - Play",
      appbar: false,
    },
  },
  {
    path: "/participant/endquiz",
    name: "ParticipantEndQuiz",
    component: () => import(/* webpackChunkName: "participantendquiz" */ "../views/ParticipantEndQuiz.vue"),
    props: true,
    meta: {
      title: "Qizto - End Quiz",
      requiresAuth: false,
      appbar: false,
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const currentUser = auth.currentUser
  if (requiresAuth && !currentUser) {
    next("Login")
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title : "Qizto"
  })
})

export default router
