// import * as Sentry from "@sentry/vue"

import { getUserProfile } from "@/api/AuthApi"
import { auth } from "@/firebase"
import AnimateCSS from "animate.css"
import { onAuthStateChanged } from "firebase/auth"
import Vue from "vue"
import App from "./App"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
// import "./registerServiceWorker"

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue()

Vue.use(AnimateCSS)

// Sentry.init({
//   Vue,
//   dsn: "https://786caf93b2cb43f1bb84b6b22738d3d4@o1130429.ingest.sentry.io/6174476",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "qizto.com", /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// })

let app
onAuthStateChanged(auth, async (user) => {
  if (!app) {
    if (user) {
      await store.dispatch("setCurrentUser", user).then(() => getUserProfile(user.uid))
    }
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
      mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
    }).$mount("#app")
  }
})
