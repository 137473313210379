<template>
  <v-app-bar id="app-bar" color="background" height="80" app>
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="6" sm="auto">
        <router-link :to="{ name: 'Quizzes' }">
          <v-img v-if="user" :src="logo_png" height="50" max-width="150" contain></v-img>
        </router-link>
      </v-col>
      <v-col cols="auto" v-if="user && userprofile && $vuetify.breakpoint.smAndUp">
        <v-tooltip v-for="link in links" :key="link.title" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              large
              icon
              right
              color="secondary"
              :to="{ name: link.route, params: link.params }"
              class="mr-2"
              v-on="on"
            >
              <font-awesome-icon :icon="link.icon" size="lg" />
            </v-btn>
          </template>
          <span>{{ link.title }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn large right icon color="secondary" class="mr-2" @click="handleLogout()" v-on="on">
              <font-awesome-icon icon="door-open" />
            </v-btn>
          </template>
          <span>Signout {{ userprofile.name }}</span>
        </v-tooltip>
        <v-avatar color="secondary" class="white--text mr-4">
          {{ initials }}
          <v-img v-if="userprofile.image" src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
        </v-avatar>
      </v-col>

      <!-- Menu for XS screens -->
      <v-col cols="auto" v-if="user && userprofile && $vuetify.breakpoint.xs">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="secondary" class="white--text" v-bind="attrs" v-on="on">
              {{ initials }}
              <v-img v-if="userprofile.image" src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
            </v-avatar>
          </template>

          <v-list class="pa-4 secondary--text">
            <v-list-item>
              <v-list-item-avatar color="secondary" class="white--text">
                {{ initials }}
                <v-img v-if="userprofile.image" src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6"> {{ userprofile.name }} </v-list-item-title>
                <v-list-item-subtitle>{{ userprofile.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="link in links"
              :key="link.title"
              @click="$router.push({ name: link.route }).catch(() => {})"
            >
              <v-list-item-icon class="secondary--text">
                <font-awesome-icon :icon="link.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">{{ link.title }}</v-list-item-title>
              </v-list-item-content> </v-list-item
            ><v-list-item>
              <v-list-item-icon>
                <font-awesome-icon icon="door-open" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text" @click="handleLogout()"> Signout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import { logout } from "@/api/AuthApi"
import { mapMutations, mapState } from "vuex"
export default {
  name: "UserAppBar",
  data: () => ({
    dark_mode_logo: require("@/assets/common/logo_darkmode.svg"),
    light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
    logo_png: require("@/assets/common/Qizto_Logo.png"),
    links: [
      {
        title: "New Quiz",
        icon: "square-plus",
        route: "Create",
        params: { type: "L" },
      },
      {
        title: "My Quizzes",
        icon: "dice-d20",
        route: "Quizzes",
      },
      {
        title: "Reports",
        icon: "file-contract",
        route: "sessions",
        params: { id: "all" },
      },
      {
        title: "Mailing List",
        icon: "envelopes-bulk",
        route: "Quizzes",
      },
      {
        title: "Profile",
        icon: "id-card",
        route: "Profile",
        params: { id: "user.uid" },
      },
    ],

    authlinks: [
      {
        title: "Login",
        icon: "sign-in-alt",
        route: "Login",
      },
      {
        title: "Sign Up",
        icon: "id-card",
        route: "Signup",
      },
    ],
    logoutSuccessMessage: "Logged Out Successfully",
    guestSignInMessage: "You are Logged in as Guest",
    loading: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      landing: (state) => state.landing,
      userprofile: (state) => state.user.userProfile,
    }),
    initials() {
      return this.$store.getters.getInitials
    },
  },
  methods: {
    ...mapMutations({
      showAlert: "SHOW_SNACKBAR",
      setUser: "SET_CURRENT_USER",
      clearUserProfile: "CLEAR_USER_PROFILE_ON_SIGNOUT",
    }),

    async handleLogout() {
      try {
        await logout()
        this.$router.push("/")
        this.setUser(null)
        this.clearUserProfile()
        this.showAlert(this.logoutSuccessMessage)
      } catch (err) {
        this.showAlert(err.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#app-bar {
  a {
    text-decoration: none;
  }
  .underline {
    text-decoration: none;
    &:hover {
      transition: all 0.1s ease-in;
      color: var(--v-secondary-base) !important;
      border-bottom: 4px solid var(--v-secondary-base);
    }
  }
  .router-link-active .underline {
    color: var(--v-accent-base);
    border-bottom: 4px solid var(--v-accent-base);
  }
}
</style>
