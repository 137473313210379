var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-overlay", { attrs: { value: _vm.loading } }, [
    _c(
      "div",
      { attrs: { align: "center", justify: "center" } },
      [
        _c("p", {
          staticClass: "primary--text",
          domProps: { textContent: _vm._s(_vm.text) }
        }),
        _c("v-progress-circular", {
          attrs: { indeterminate: "", size: "64", color: "primary" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }