var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-container",
        {
          staticClass: "white",
          attrs: {
            id: "comingsoon",
            "fill-height": "",
            fluid: _vm.$vuetify.breakpoint.mdAndDown
          }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "mt-4",
              attrs: { fluid: _vm.$vuetify.breakpoint.mdAndDown }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-center",
                  attrs: { align: "center" }
                },
                [
                  _c("v-col", { staticClass: "pt-4", attrs: { cols: "12" } }, [
                    _c(
                      "h3",
                      { staticClass: "text-center text-h4 font-weight-black" },
                      [_vm._v(_vm._s(_vm.section_title))]
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "text-h5 text-center mx-4 px-6 py-1 font-weight-medium"
                      },
                      [_vm._v(" " + _vm._s(_vm.section_subtitle) + " ")]
                    )
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass: "align-stretch",
                      attrs: { align: "center", justify: "center" }
                    },
                    _vm._l(_vm.cards, function(card, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "d-flex justify-center py-4 py-sm-8",
                          attrs: { cols: "12", sm: "6", md: "6", lg: "5" }
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "d-flex flex-grow-1",
                              style: { borderColor: card.border }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "ma-0 pa-4 inner-card",
                                  attrs: { color: card.color, outlined: "" }
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "mx-auto my-6",
                                    attrs: {
                                      src: card.image,
                                      height: "80",
                                      width: "80",
                                      contain: ""
                                    }
                                  }),
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "text-h6 text-md-h5 justify-center mb-2 font-weight-bold"
                                    },
                                    [_vm._v(_vm._s(card.title))]
                                  ),
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "subtitle-1 d-inline-flex text-center text-md-h6 font-weight-medium"
                                    },
                                    [_vm._v(" " + _vm._s(card.subtitle) + " ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }