<template>
  <section>
    <v-container
      id="hero"
      :class="$vuetify.breakpoint.smAndUp ? 'hero-background-large' : 'hero-background-small'"
      fill-height
      fluid
    >
      <v-container class="pa-2 pa-sm-6" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center" justify="center" class="mt-2 d-flex justify-space-around">
          <v-col cols="12" md="6" lg="6" class="d-flex justify-center px-lg-4">
            <HeroImage />
          </v-col>
          <v-col cols="12" md="6" lg="6" class="px-lg-4">
            <h1 class="mb-6 font-weight-black secondary--text text-md-h2 text-h3">
              {{ headline }}
            </h1>
            <!-- animate__animated animate__rubberBand -->
            <h2 class="font-weight-medium text-h6 text-md-h5">
              {{ subtitle }}
            </h2>
            <v-col cols="12" sm="10" class="d-flex mt-4 pa-0">
              <GuestLoginBtn />
              <v-btn
                x-large
                depressed
                outlined
                color="secondary"
                to="/join"
                class="ml-2 text-h6 font-weight-bold white--text flex-grow-1"
              >
                <font-awesome-icon icon="play" class="mr-2" />
                Join Quiz
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>

<script>
import GuestLoginBtn from "@/components/common/GuestLoginBtn.vue"
import HeroImage from "@/components/landing/HeroImage.vue"
export default {
  name: "Hero",
  components: {
    GuestLoginBtn,
    HeroImage,
  },
  data() {
    return {
      headline: "Captivate your audience with fun interactions",
      subtitle: `User Engagement Platform to build great interactive polls and quizzes to engross people at work or in online
            classrooms.`,
    }
  },
}
</script>

<style lang="scss" scoped>
#hero {
  min-height: 95vh;
  background-color: #fff3e0;
}
// .hero-background-large {
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: bottom bottom;
//   background-image: url("~@/assets/background-bottom.svg");
//   background-blend-mode: overlay;
// }
.hero-background-small {
  background-color: #fff3e0;
}
</style>
