var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-container",
        {
          staticClass: "pa-lg-12",
          style: {
            backgroundImage:
              "url(" + require("@/assets/landing/features/section3bg.png") + ")"
          },
          attrs: { id: "features", "fill-height": "", fluid: "" }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "mt-4",
              attrs: { fluid: _vm.$vuetify.breakpoint.mdAndDown }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-space-around align-stretch",
                  attrs: { align: "center" }
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h3",
                      { staticClass: "text-center text-h4 font-weight-black" },
                      [_vm._v(_vm._s(_vm.section_title))]
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "text-h5 text-center mx-4 px-6 py-1 font-weight-medium"
                      },
                      [_vm._v(" " + _vm._s(_vm.section_subtitle) + " ")]
                    )
                  ]),
                  _vm._l(_vm.cards, function(card, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        staticClass: "my-md-8",
                        attrs: { cols: "auto", sm: "6", md: "4" }
                      },
                      [
                        _c("v-img", {
                          staticClass: "ml-4",
                          attrs: {
                            src: card.image,
                            "max-height": "50",
                            "max-width": "50",
                            contain: ""
                          }
                        }),
                        _c(
                          "v-card-title",
                          {
                            staticClass:
                              "text-h6 text-md-h5 font-weight-bold mb-2"
                          },
                          [_vm._v(" " + _vm._s(card.title) + " ")]
                        ),
                        _c(
                          "v-card-subtitle",
                          {
                            staticClass:
                              "subtitle-1 text-md-h6 font-weight-medium black--text wrap"
                          },
                          [_vm._v(" " + _vm._s(card.subtitle) + " ")]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }