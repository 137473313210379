var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c(
        "v-btn",
        {
          staticClass: "text-h6 font-weight-bold",
          attrs: { "x-large": "", block: "", color: "secondary" },
          on: {
            "~click": function($event) {
              return _vm.anonymousSignIn.apply(null, arguments)
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.btntext) + " ")]
      ),
      _c("Loading", {
        attrs: { loading: _vm.loading, text: _vm.guestlogintext }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }