var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-container",
        {
          class: _vm.$vuetify.breakpoint.smAndUp
            ? "hero-background-large"
            : "hero-background-small",
          attrs: { id: "hero", "fill-height": "", fluid: "" }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "pa-2 pa-sm-6",
              attrs: { fluid: _vm.$vuetify.breakpoint.mdAndDown }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-2 d-flex justify-space-around",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center px-lg-4",
                      attrs: { cols: "12", md: "6", lg: "6" }
                    },
                    [_c("HeroImage")],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-lg-4",
                      attrs: { cols: "12", md: "6", lg: "6" }
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "mb-6 font-weight-black secondary--text text-md-h2 text-h3"
                        },
                        [_vm._v(" " + _vm._s(_vm.headline) + " ")]
                      ),
                      _c(
                        "h2",
                        {
                          staticClass: "font-weight-medium text-h6 text-md-h5"
                        },
                        [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex mt-4 pa-0",
                          attrs: { cols: "12", sm: "10" }
                        },
                        [
                          _c("GuestLoginBtn"),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "ml-2 text-h6 font-weight-bold white--text flex-grow-1",
                              attrs: {
                                "x-large": "",
                                depressed: "",
                                outlined: "",
                                color: "secondary",
                                to: "/join"
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "play" }
                              }),
                              _vm._v(" Join Quiz ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }