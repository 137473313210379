<template>
  <div class="rotating-image-container">
    <v-img
      class="rotating-image"
      src="@/assets/landing/hero/Background_Circle.svg"
      :max-width="dimension"
      :max-height="dimension"
      contain
    >
    </v-img>
    <v-img
      lazy-src="@/assets/landing/hero/Circle_With_Mockup.svg"
      class="fixed-image"
      src="@/assets/landing/hero/Circle_With_Mockup_new.svg"
      :max-width="dimension"
      :max-height="dimension"
      contain
    ></v-img>
  </div>
</template>

<script>
export default {
  name: "HeroImage",
  computed: {
    dimension() {
      return this.$vuetify.breakpoint.xs
        ? 300
        : this.$vuetify.breakpoint.sm
        ? 400
        : this.$vuetify.breakpoint.md
        ? 420
        : 580
    },
  },
}
</script>

<style lang="scss" scoped>
.rotating-image-container {
  top: 0;
  left: 0;
  position: relative;
  z-index: 0;
}
.rotating-image {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-animation: spin 20s linear infinite;
  -moz-animation: spin 20s linear infinite;
  animation: spin 20s linear infinite;
}
.fixed-image {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
