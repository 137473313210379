<template>
  <v-app>
    <v-snackbar v-model="snackbar" top color="accent" elevation="24">
      {{ snacktext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <AppBar v-if="$route.meta.appbar && !user" />
    <UserAppBar v-if="$route.meta.appbar && user" />
    <v-main class="ma-0">
      <router-view id="main"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import AppBar from "@/components/common/AppBar.vue"
import UserAppBar from "@/components/common/UserAppBar.vue"

export default {
  name: "App",
  components: {
    AppBar,
    UserAppBar,
  },
  methods: {
    ...mapMutations({ hideAlert: "HIDE_SNACKBAR" }),
  },
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      snacktext: "snacktext",
      snackTimeOut: "snackTimeOut",
    }),
    snackbar: {
      get() {
        return this.$store.state.snackbar
      },
      set() {
        this.hideAlert()
      },
    },
  },
}
</script>

<style lang="scss">
// * {
//   outline: 1px dotted gray;
// }
#pin {
  position: absolute;
  width: 80%;
  top: -2%;
  left: 2%;
  right: 2%;
  border-radius: 0 !important;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.li a {
  text-decoration: none;
  color: var(--v-secondary-base);
}
.v-btn {
  text-transform: none;
  font-size: 1em !important;
  font-weight: bold !important;
  &:hover {
    i {
      transition: all 0.5s ease;
      transform: rotate(360deg);
    }
    .rotate {
      transition: all 0.5s ease;
      transform: rotate(360deg);
    }
  }
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
