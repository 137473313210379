var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "v-container",
      { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
      [
        _c("Hero"),
        _c("GetStarted"),
        _c("Features"),
        _c("ComingSoon"),
        _c("Testimonials"),
        _c("Footer")
      ],
      1
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }