// import "@fortawesome/fontawesome-free/css/all.min.css"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Vue from "vue"
import Vuetify, {
  VChip,
  VDialog,
  VInput,
  VLabel,
  VMessages,
  VRadio,
  VRadioGroup,
  VSimpleTable,
  VSlider,
  VTextField,
} from "vuetify/lib"
import { CUSTOM_ICONS } from "./fontAwesome"

Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.use(Vuetify, {
  components: {
    VSlider,
    VLabel,
    VInput,
    VTextField,
    VMessages,
    VSimpleTable,
    VRadioGroup,
    VRadio,
    VChip,
    VDialog,
  },
})

export default new Vuetify({
  icons: {
    iconfont: "fa",
    values: CUSTOM_ICONS,
    // values: {
    //   checkboxOff: "fas fa-square",
    //   checkboxOn: "fas fa-check-square",
    //   radioOn: "fas fa-circle",
    //   radioOff: "fas fa-check-circle",
    // },
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#244166",
        // secondary: "#254166",
        secondary: "#305383",
        // secondary: "#384b81",
        // secondary: "#5f77ba",
        accent: "#4a70a3",
        accent1: "#ff696f",
        // accent2: "#ffdfad",
        accent2: "#ffe1b3",
        accent3: "#fffdf3",
        error: "#E66360",
        // error: "#FF5252",
        info: "#2196F3",
        // success: "#4CAF50",
        success: "#00cdd2",
        // warning: "#FF8F00",
        warning: "#ff9a1a",
        background: "#fff3e0",
      },
      dark: {
        background: "#fff3e0",
        primary: "#244166",
        secondary: "#ffffff",
        accent: "#ff9891",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FF8F00",
      },
    },
  },
})
