import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: `${process.env.VUE_APP_FB_PROJECTID}.firebaseapp.com`,
  databaseURL: `https://${process.env.VUE_APP_FB_PROJECTID}.firebaseio.com`,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  storageBucket: `${process.env.VUE_APP_FB_PROJECTID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FB_APPID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENTID,
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth()
// !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : ""

// if (window.location.hostname === "localhost") {
//   database.useEmulator("localhost", 9080)
//   storage.useEmulator("localhost", 8085)
//   auth.useEmulator("http://localhost:9099")
// }

export { firebaseApp, auth }
