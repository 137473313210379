<template>
  <section>
    <v-container
      id="features"
      :style="{ backgroundImage: 'url(' + require('@/assets/landing/features/section3bg.png') + ')' }"
      class="pa-lg-12"
      fill-height
      fluid
    >
      <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center" class="d-flex justify-space-around align-stretch">
          <v-col cols="12">
            <h3 class="text-center text-h4 font-weight-black">{{ section_title }}</h3>
          </v-col>
          <v-col cols="12">
            <h4 class="text-h5 text-center mx-4 px-6 py-1 font-weight-medium">
              {{ section_subtitle }}
            </h4>
          </v-col>
          <v-col cols="auto" sm="6" md="4" v-for="(card, index) in cards" :key="index" class="my-md-8">
            <v-img class="ml-4" :src="card.image" max-height="50" max-width="50" contain> </v-img>
            <v-card-title class="text-h6 text-md-h5 font-weight-bold mb-2">
              {{ card.title }}
            </v-card-title>
            <v-card-subtitle class="subtitle-1 text-md-h6 font-weight-medium black--text wrap">
              {{ card.subtitle }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Features",
  data() {
    return {
      section_title: "Features",
      section_subtitle: `Include everyone in the discourse whether in work or for education we have everything for everyone.`,
      cards: [
        {
          title: "Question Variety",
          subtitle: `Multiple Choice, True or False, or just a poll, or just have fun combining everything, you decide we provide.`,
          image: require("@/assets/landing/features/question_variety.svg"),
        },
        {
          title: "Lifelines",
          subtitle: `What’s a game without some helpful clues? Include lifelines, remove answer options, optimize scores accordingly.`,
          image: require("@/assets/landing/features/lifelines.svg"),
        },
        {
          title: "Go Live",
          subtitle: `Play the game live with anyone and everyone simultaneously. There is no restriction on users, it’s an open invite!`,
          image: require("@/assets/landing/features/livegameplay.svg"),
        },
        {
          title: "Answer Sheets",
          subtitle: `See each participant's answer sheet with microscopic information regarding how each participant responded to the quiz or poll.`,
          image: require("@/assets/landing/features/answer_sheets.svg"),
        },
        {
          title: "Leaderboard & Stats",
          subtitle: `A real-time leader board gives you stats that show the front & fastest runners helping increase engagement during game play.`,
          image: require("@/assets/landing/features/leaderboard.svg"),
        },
        {
          title: "Start Free of Cost",
          subtitle: `Keep the credit card in your wallet and begin absolutely free. We guarantee a functional free version will always be available.`,
          image: require("@/assets/landing/features/free.svg"),
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
#features {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 95vh;
}
.wrap {
  word-break: normal;
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
</style>
