<template>
  <section>
    <v-container class="white" justify="center" id="getstarted" fluid>
      <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center" class="d-flex justify-space-around">
          <v-col cols="12" class="pt-4">
            <h3 class="text-center text-h4 font-weight-black">{{ section_title }}</h3>
          </v-col>
          <v-col cols="12">
            <h4 class="text-h5 text-center mx-4 px-6 py-1 font-weight-medium">
              {{ section_subtitle }}
            </h4>
          </v-col>
          <v-row align="center" justify="center" class="align-stretch py-4 py-md-8">
            <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-for="(card, index) in cards"
              :key="index"
              class="d-flex justify-center"
            >
              <v-sheet :style="{ borderColor: card.border }" class="d-flex flex-grow-1">
                <v-card :color="card.color" outlined class="pa-2 inner-card pa-md-6">
                  <v-card-title class="text-h6 text-md-h5 justify-center mb-2 font-weight-black">{{
                    card.title
                  }}</v-card-title>
                  <v-card-subtitle class="font-weight-medium text-center subtitle-1 text-md-h6">{{
                    card.subtitle
                  }}</v-card-subtitle>
                  <v-img class="mx-auto mt-2" :src="card.image" max-height="120" max-width="200" contain> </v-img>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
          <v-col cols="12">
            <h5 class="ma-4 pa-6 text-center font-weight-medium text-h6 text-md-h5">
              {{ message }}
            </h5>
          </v-col>
          <v-col cols="12" md="5" xl="4">
            <v-img class="mx-auto" :src="cta_image" max-height="800" max-width="600"> </v-img>
          </v-col>
          <v-col cols="12" md="7" xl="6" class="pa-lg-12">
            <h6 class="text-h4 text-md-h3 font-weight-black">{{ cta_title }}</h6>
            <p class="subtitle-1 text-md-h6 font-weight-medium">{{ cta_subtitle }}</p>
            <v-col cols="12" md="4" lg="4" xl="6">
              <GuestLoginBtn />
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>

<script>
import GuestLoginBtn from "@/components/common/GuestLoginBtn.vue"
export default {
  name: "GetStarted",
  components: {
    GuestLoginBtn,
  },
  data() {
    return {
      section_title: "How Qizto Works!",
      section_subtitle: "It's Quick, Fun & Simple to Get Started",
      message: `"The awesome thing about Qizto is that it helps to measure real engagement rather than just grading for participation."`,
      cta_title: "Time to put your audience's knowledge to the test!",
      cta_subtitle:
        "In a flash, create an engaging quiz and polls or a trivia game. Put two or more participants up against each other. Get immediate feedback!",
      cta_image: require("@/assets/landing/getstarted/circle_screen.svg"),
      cards: [
        {
          title: "Prepare",
          subtitle:
            "Build quizzes or polls in minutes with our intuitive editor. And the Fun part - Add gifs and lifelines too!",
          image: require("@/assets/landing/getstarted/addpoll.png"),
          color: "#ffeef1",
          border: "#ff849d",
        },
        {
          title: "Play",
          subtitle:
            "Host the quiz, share  and invite participants to play. Enjoy the game & see real-time responses & leaderboard.",
          image: require("@/assets/landing/getstarted/participant.png"),
          color: "#fff3e0",
          border: "#fec363",
        },
        {
          title: "Evaluate",
          subtitle: `Get instant meaningful feedback and in-depth reviews with detailed answer sheets and scorecards`,
          image: require("@/assets/landing/getstarted/feedback.png"),
          color: "#efefff",
          border: "#ab93d8",
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.v-sheet {
  border-style: solid;
  border-radius: 2rem;
  border-width: 0.1rem 0.5rem 0.5rem 0.1rem;
}
.inner-card {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
</style>
