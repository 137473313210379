var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-container",
        {
          staticClass: "white",
          attrs: { justify: "center", id: "getstarted", fluid: "" }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "mt-4",
              attrs: { fluid: _vm.$vuetify.breakpoint.mdAndDown }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-space-around",
                  attrs: { align: "center" }
                },
                [
                  _c("v-col", { staticClass: "pt-4", attrs: { cols: "12" } }, [
                    _c(
                      "h3",
                      { staticClass: "text-center text-h4 font-weight-black" },
                      [_vm._v(_vm._s(_vm.section_title))]
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "text-h5 text-center mx-4 px-6 py-1 font-weight-medium"
                      },
                      [_vm._v(" " + _vm._s(_vm.section_subtitle) + " ")]
                    )
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass: "align-stretch py-4 py-md-8",
                      attrs: { align: "center", justify: "center" }
                    },
                    _vm._l(_vm.cards, function(card, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "12", sm: "6", md: "4", xl: "3" }
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "d-flex flex-grow-1",
                              style: { borderColor: card.border }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-2 inner-card pa-md-6",
                                  attrs: { color: card.color, outlined: "" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "text-h6 text-md-h5 justify-center mb-2 font-weight-black"
                                    },
                                    [_vm._v(_vm._s(card.title))]
                                  ),
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "font-weight-medium text-center subtitle-1 text-md-h6"
                                    },
                                    [_vm._v(_vm._s(card.subtitle))]
                                  ),
                                  _c("v-img", {
                                    staticClass: "mx-auto mt-2",
                                    attrs: {
                                      src: card.image,
                                      "max-height": "120",
                                      "max-width": "200",
                                      contain: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "ma-4 pa-6 text-center font-weight-medium text-h6 text-md-h5"
                      },
                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "5", xl: "4" } },
                    [
                      _c("v-img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: _vm.cta_image,
                          "max-height": "800",
                          "max-width": "600"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-lg-12",
                      attrs: { cols: "12", md: "7", xl: "6" }
                    },
                    [
                      _c(
                        "h6",
                        { staticClass: "text-h4 text-md-h3 font-weight-black" },
                        [_vm._v(_vm._s(_vm.cta_title))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "subtitle-1 text-md-h6 font-weight-medium"
                        },
                        [_vm._v(_vm._s(_vm.cta_subtitle))]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4", lg: "4", xl: "6" } },
                        [_c("GuestLoginBtn")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }