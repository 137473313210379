var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "", color: "accent", elevation: "24" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "success", text: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snacktext) + " ")]
      ),
      _vm.$route.meta.appbar && !_vm.user ? _c("AppBar") : _vm._e(),
      _vm.$route.meta.appbar && _vm.user ? _c("UserAppBar") : _vm._e(),
      _c(
        "v-main",
        { staticClass: "ma-0" },
        [_c("router-view", { attrs: { id: "main" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }