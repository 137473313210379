<template>
  <v-overlay :value="loading">
    <div align="center" justify="center">
      <p v-text="text" class="primary--text"></p>
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: String,
      default: () => "Loading...Please Wait!",
    },
  },
}
</script>
