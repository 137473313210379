import { firebaseApp } from "@/firebase"
import store from "@/store"
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore"
import { deleteObject, getStorage, listAll, ref } from "firebase/storage"
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const quizesref = collection(db, "quizes")

async function createQuiz(quiz) {
  const quizref = doc(quizesref)
  const quizid = quizref.id
  const quizdata = { ...quiz, quizid: quizid }
  await setDoc(quizref, quizdata)
  return quizid
}

async function getQuiz(id) {
  if (id) {
    const unsubscribe = onSnapshot(doc(db, "quizes", id), (doc) => {
      store.dispatch("setCurrentQuiz", { ...doc.data(), id: doc.id })
    })
    store.commit("SET_QUIZ_LISTENER", unsubscribe)
  } else {
    store.dispatch("setCurrentQuiz", {})
  }
}

async function fetchQuizzes(user) {
  const q = query(quizesref, where("userId", "==", user))
  const unsubscribe = onSnapshot(q, (qs) => {
    var quizzes = []
    qs.forEach((doc) => {
      quizzes.push(doc.data())
    })
    store.dispatch("setQuizzes", quizzes)
  })
  store.commit("SET_QUIZ_LIST_LISTENER", unsubscribe)
}

async function updateQuiz(quiz) {
  const quizref = doc(db, "quizes", quiz.id)
  await setDoc(quizref, quiz, { merge: true })
}

async function deleteQuizAttachments(uid, id) {
  const listRef = ref(storage, `images/${uid}/${id}`)
  const res = await listAll(listRef)
  const promises = res.items.map((itemRef) => {
    return deleteObject(itemRef)
  })
  return Promise.all(promises)
}

async function deleteQuizSessions(quizid) {
  const qs = await getDocs(collection(db, "quizes", quizid, "sessions"))
  qs.forEach(async (document) => {
    await deleteDoc(doc(db, "quizes", quizid, "sessions", document.id))
  })
  await deleteDoc(doc(db, "quizes", quizid))
}

async function deleteSession(quizid, sessionid) {
  const qs = await getDocs(collection(db, "quizes", quizid, "sessions", sessionid, "participantsessions"))
  qs.forEach(async (document) => {
    await deleteDoc(doc(db, "quizes", quizid, "sessions", sessionid, "participantsessions", document.id))
  })
  await deleteDoc(doc(db, "quizes", quizid, "sessions", sessionid))
}

async function deleteQuiz(quizid) {
  await deleteDoc(doc(db, "quizes", quizid))
}

async function getAllSessions(quizid, user) {
  if (quizid === "all") {
    const q = query(collectionGroup(db, "sessions"), where("userId", "==", user), orderBy("createdAt", "desc"))
    const unsubscribe = onSnapshot(q, (qs) => {
      var sessions = []
      qs.forEach((doc) => {
        sessions.push({ ...doc.data(), id: doc.id })
      })
      store.dispatch("setGameSessions", sessions)
    })
    store.commit("SET_SESSIONS_LISTENER", unsubscribe)
  } else {
    const sessionsref = collection(quizesref, quizid, "sessions")
    const q = query(sessionsref, orderBy("createdAt", "desc"))
    const unsubscribe = onSnapshot(q, (qs) => {
      var sessions = []
      qs.forEach((doc) => {
        sessions.push({ ...doc.data(), id: doc.id })
      })
      store.dispatch("setGameSessions", sessions)
    })
    store.commit("SET_SESSIONS_LISTENER", unsubscribe)
  }
}

async function getAllParticipantSessions(session) {
  const participantsessionsref = collection(db, "quizes", session.quizid, "sessions", session.id, "participantsessions")
  const q = query(participantsessionsref, orderBy("totalscore", "desc"))
  const qs = await getDocs(q)
  var sessions = []
  qs.forEach((doc) => {
    sessions.push({ ...doc.data(), id: doc.id })
  })
  store.dispatch("setParticipantSessions", sessions)
}

export {
  createQuiz,
  getQuiz,
  updateQuiz,
  fetchQuizzes,
  deleteQuiz,
  getAllSessions,
  getAllParticipantSessions,
  deleteQuizAttachments,
  deleteQuizSessions,
  deleteSession,
}
