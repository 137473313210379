import { auth, firebaseApp } from "@/firebase"
import store from "@/store"
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth"
import { collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore"
const db = getFirestore(firebaseApp)
const usersref = collection(db, "user")

async function login(email, password) {
  let { user } = await signInWithEmailAndPassword(auth, email, password)
  await getUserProfile(user.uid)
  store.dispatch("setCurrentUser", user)
}

async function signup(email, password, name) {
  let { user } = await createUserWithEmailAndPassword(auth, email, password)
  updateProfile(user, { displayName: name })
  await createUserProfile(user, email, name)
}

async function guestSignIn() {
  let { user } = await signInAnonymously(auth)
  const displayName = `guest${user.uid}`
  updateProfile(user, { displayName: displayName })
  await createUserProfile(user, "", displayName)
}

async function convertGuestToUser(email, password, name) {
  const credential = EmailAuthProvider.credential(email, password)
  let { user } = await linkWithCredential(auth.currentUser, credential)
  await updateProfile(user, { displayName: name })
  await updateUserProfile({ id: user.uid, email: email, name: name })
}

async function logout() {
  await signOut(auth)
}

async function resetPassword(email) {
  await sendPasswordResetEmail(auth, email)
}

async function getUserProfile(userid) {
  const docRef = doc(db, "user", userid)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    store.dispatch("bindUserProfile", docSnap.data())
  } else {
    throw new Error("User Profile Does not Exist")
  }
}

async function updateUserProfile(userprofile) {
  const userref = doc(db, "user", userprofile.id)
  await setDoc(userref, userprofile, { merge: true })
  store.dispatch("bindUserProfile", userprofile)
}

async function createUserProfile(user, email, name) {
  let userId = user.uid
  let userProfileData = {
    id: userId,
    name: name,
    email: email,
    role: "USER",
    questiontimer: true,
    uploadstatus: true,
    darkmode: false,
  }
  console.log(userId, userProfileData)
  const userref = doc(usersref, userId)
  await setDoc(userref, userProfileData, { merge: true })
  store.dispatch("setCurrentUser", user)
  store.dispatch("bindUserProfile", userProfileData)
}

export { login, signup, getUserProfile, updateUserProfile, logout, guestSignIn, convertGuestToUser, resetPassword }
