<template>
  <section>
    <v-container fluid id="footer">
      <v-container>
        <v-row class="d-flex justify-space-between">
          <v-col cols="12" sm="3">
            <router-link :to="{ name: 'Landing', hash: '#hero' }" aria-label="Home">
              <v-col>
                <a href="/landing">
                  <v-img max-height="180" max-width="180" :src="light_mode_logo"></v-img>
                </a>
              </v-col>
            </router-link>
            <v-btn
              v-for="link in sociallinks"
              :key="link.name"
              color="#244166"
              icon
              large
              class="mx-2"
              :to="link.to"
              :aria-label="link.name"
            >
              <font-awesome-icon :icon="link.icon" size="2x" />
            </v-btn>
            <v-card-text class="caption font-weight-bold"> {{ new Date().getFullYear() }} @ qizto </v-card-text>
          </v-col>
          <v-col cols="12" sm="4" class="flex-grow-1">
            <p class="text-h6 font-weight-bold">Links</p>
            <router-link
              v-for="link in homelinks"
              :key="link.title"
              class="subtitle-1 d-inline-flex mr-3 underline secondary--text"
              text
              :to="link.route"
            >
              {{ link.title }}
            </router-link>
          </v-col>
          <v-col cols="12" sm="4" class="flex-grow-1">
            <p class="text-h6 font-weight-bold">Contact Us</p>
            <p class="subtitle-1 font-weight-medium text-justify">
              +91 9840594865<br />
              hello@quizto.com<br />
              Venzo Technologies Pvt Ltd<br />
              No.236, 8th Floor, Purva Primus,<br />
              Thoraipakkam, Chennai 600 097.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
      sociallinks: [
        { name: "Facebook", icon: ["fab", "facebook-f"], to: "/" },
        { name: "Twitter", icon: ["fab", "twitter"], to: "/" },
        { name: "Instagram", icon: ["fab", "instagram"], to: "/" },
      ],
      homelinks: [
        { title: "Home", route: { name: "Landing", hash: "#hero" } },
        { title: "How it Works", route: { name: "Landing", hash: "#getstarted" } },
        { title: "Features", route: { name: "Landing", hash: "#features" } },
        { title: "Coming Soon", route: { name: "Landing", hash: "#comingsoon" } },
        { title: "Testimonials", route: { name: "Landing", hash: "#testimonials" } },
        { title: "Privacy Policy", route: { name: "Landing", hash: "#footer" } },
        { title: "Terms of Service", route: { name: "Landing", hash: "#footer" } },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
#footer {
  a {
    text-decoration: none;
  }
  .underline {
    text-decoration: none;
    &:hover {
      transition: all 0.1s ease-in;
      color: var(--v-secondary-base) !important;
      border-bottom: 4px solid var(--v-secondary-base);
    }
  }
  .router-link-active .underline {
    color: var(--v-accent-base);
    border-bottom: 4px solid var(--v-accent-base);
  }
}
</style>
