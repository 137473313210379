const state = () => ({
  userProfile: {
    darkmode: false,
    email: "",
    id: "",
    name: "",
    questiontimer: null,
    role: null,
    uploadstatus: null,
  },
  currentUser: null,
  alertmsgs: {
    userprofileupdate: "User Preferences Updated",
    unexpected:
      "Oops..I did not expect this to happen. Please Try Again Hopefully it will be alright. If issue persists please write to support@qizto.com",
  },
})

const getters = {
  getInitials: (state) => {
    if (state.userProfile.name) {
      const words = state.userProfile.name.split(/[\s-]+/)
      let intls = ""
      if (words.length > 1) {
        for (let i = 0; i < words.length; i++) {
          intls += words[i].substring(0, 1).toUpperCase()
          if (intls.length > 3) {
            intls = intls.substring(0, 3)
          }
        }
      } else {
        intls += words[0].substring(0, 2).toUpperCase()
      }
      return intls
    } else {
      return
    }
  },
}

const mutations = {
  SET_CURRENT_USER: (state, payload) => {
    state.currentUser = JSON.parse(JSON.stringify(payload))
  },

  CLEAR_USER_PROFILE_ON_SIGNOUT(state) {
    state.userProfile = {
      darkmode: false,
      email: "",
      id: "",
      name: "",
      questiontimer: null,
      role: null,
      uploadstatus: null,
    }
  },

  SET_USER_PROFILE(state, userprofile) {
    Object.assign(state.userProfile, userprofile)
  },
}

const actions = {
  setCurrentUser: ({ commit }, user) => {
    commit("SET_CURRENT_USER", user)
  },

  bindUserProfile: ({ commit }, userdata) => {
    console.log("binding user profile", userdata)
    commit("SET_USER_PROFILE", userdata)
  },

  // updateUserProfile: async ({ state, commit }, profile) => {
  //   try {
  //     await usersRef.doc(state.currentUser.uid).set(profile, { merge: true })
  //     commit("SHOW_SNACKBAR", state.alertmsgs.userprofileupdate, { root: true })
  //   } catch (err) {
  //     commit("SHOW_SNACKBAR", `${state.alertmsgs.unexpected} - ${err.message}`, { root: true })
  //   }
  // },

  // updateDarkMode: async ({ state, commit }, darkmode) => {
  //   try {
  //     await usersRef.doc(state.currentUser.uid).set({ darkmode: darkmode }, { merge: true })
  //     commit("SHOW_SNACKBAR", state.alertmsgs.userprofileupdate, { root: true })
  //   } catch (err) {
  //     commit("SHOW_SNACKBAR", `${state.alertmsgs.unexpected} - ${err.message}`, { root: true })
  //   }
  // },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
