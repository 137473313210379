<template>
  <section>
    <v-container class="white" id="comingsoon" fill-height :fluid="$vuetify.breakpoint.mdAndDown">
      <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center" class="d-flex justify-center">
          <v-col cols="12" class="pt-4">
            <h3 class="text-center text-h4 font-weight-black">{{ section_title }}</h3>
          </v-col>
          <v-col cols="12">
            <h4 class="text-h5 text-center mx-4 px-6 py-1 font-weight-medium">
              {{ section_subtitle }}
            </h4>
          </v-col>
          <v-row align="center" justify="center" class="align-stretch">
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="5"
              v-for="(card, index) in cards"
              :key="index"
              class="d-flex justify-center py-4 py-sm-8"
            >
              <v-sheet :style="{ borderColor: card.border }" class="d-flex flex-grow-1">
                <v-card class="ma-0 pa-4 inner-card" :color="card.color" outlined>
                  <v-img class="mx-auto my-6" :src="card.image" height="80" width="80" contain> </v-img>

                  <v-card-title class="text-h6 text-md-h5 justify-center mb-2 font-weight-bold">{{
                    card.title
                  }}</v-card-title>
                  <v-card-subtitle class="subtitle-1 d-inline-flex text-center text-md-h6 font-weight-medium">
                    {{ card.subtitle }}
                  </v-card-subtitle>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "ComingSoon",
  data() {
    return {
      section_title: "We Are Just Getting Started!!",
      section_subtitle: "There will be some exciting new features coming soon",
      cards: [
        {
          title: "App’s the Way Forward",
          subtitle: `User-friendly iOS and Android app which allows users comfort to play at their convenience. Forget the browser just enter the PIN and you are on your way.`,
          image: require("@/assets/landing/coming_soon/ios_android.svg"),
          color: "#efefff",
          border: "#7448c3",
        },
        {
          title: "Exam Mode",
          subtitle: `Empower students and help them prepare for exams through individual tests that can be attempted anytime. Generate completion certificates.`,
          image: require("@/assets/landing/coming_soon/exam_mode.svg"),
          color: "#fff6e8",
          border: "#f8b13f",
        },
        {
          title: "Question Banks",
          subtitle: `Improve your child’s knowledge; we'll provide a common collection of questions about intriguing topics in Science, Math, Language, and History`,
          image: require("@/assets/landing/coming_soon/question_banks.svg"),
          color: "#ffeef1",
          border: "#fd5c62",
        },
        {
          title: "Dynamic Challenges",
          subtitle: `To make the quizzes even more challenging, we'll bring feature to randomize the question sequence and response choices. Just a lil more tricky!`,
          image: require("@/assets/landing/coming_soon/randomizing.svg"),
          color: "#e2fbff",
          border: "#42d7f1",
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
#comingsoon {
  min-height: 90vh;
}
.v-sheet {
  border-style: solid;
  border-radius: 2rem;
  border-width: 0.1rem 0.5rem 0.5rem 0.1rem;
}
.inner-card {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
</style>
