var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        id: "app-bar",
        color: "background",
        height: "80",
        app: "",
        "elevate-on-scroll": ""
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "d-flex justify-space-between align-center",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { staticClass: "justify-center align-start", attrs: { cols: "2" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Landing" }, "aria-label": "Home" } },
                [
                  _c("v-img", {
                    attrs: { src: _vm.logo_png, height: "60", contain: "" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-end",
                  attrs: { cols: "9" }
                },
                [
                  _vm._l(_vm.homelinks, function(link) {
                    return _c(
                      "router-link",
                      {
                        key: link.title,
                        staticClass:
                          "mx-4 text-h6 underline font-weight-bold secondary--text",
                        attrs: { text: "", to: link.route }
                      },
                      [_vm._v(" " + _vm._s(link.title) + " ")]
                    )
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 ml-lg-4 font-weight-bold",
                      attrs: { color: "secondary", large: "", to: "/login" }
                    },
                    [_c("span", [_vm._v("Login")])]
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 font-weight-bold",
                      attrs: { large: "", color: "secondary", to: "/login" }
                    },
                    [_c("span", [_vm._v("Login")])]
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          large: "",
                                          icon: "",
                                          color: "accent"
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "bars", size: "lg" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        92805894
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-2" },
                        _vm._l(_vm.homelinks, function(link) {
                          return _c(
                            "v-list-item",
                            { key: link.title },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: link.route } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(link.title))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }