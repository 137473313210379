import Vue from "vue"
import Vuex from "vuex"
import game from "./modules/game"
import quiz from "./modules/quiz"
import user from "./modules/user"

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    quiz,
    game,
    user,
  },
  state: {
    snackbar: false,
    snacktext: "",
    snackTimeOut: 3000,
    alertmsgs: {
      userprofileupdate: "User Preferences Updated",
      unexpected:
        "Oops..I did not expect this to happen. Please Try Again Hopefully it will be alright. If issue persists please write to support@qizto.com",
    },
  },

  getters: {},

  mutations: {
    SHOW_SNACKBAR: (state, payload) => {
      state.snackbar = true
      state.snacktext = payload
    },
    HIDE_SNACKBAR: (state) => {
      state.snackbar = false
    },
  },

  actions: {
    showSnackBar: ({ commit }, message) => {
      commit("SHOW_SNACKBAR", message)
    },
  },
})

export default store
