<template>
  <section>
    <v-container class="white mx-0 py-lg-12" id="testimonials" fluid fill-height>
      <v-container fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12">
            <h3 class="text-center text-h4 font-weight-black">{{ section_title }}</h3>
          </v-col>
          <v-col cols="12">
            <h4 class="text-h6 text-center mx-4 px-6 py-1 font-weight-medium">
              {{ section_subtitle }}
            </h4>
          </v-col>
        </v-row>
        <v-carousel
          cycle
          interval="8000"
          :height="$vuetify.breakpoint.smAndUp ? 400 : 600"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <template v-slot:prev="{ on, attrs }">
            <font-awesome-icon icon="arrow-circle-left" size="2x" v-bind="attrs" v-on="on"></font-awesome-icon>
          </template>
          <template v-slot:next="{ on, attrs }">
            <font-awesome-icon icon="arrow-circle-right" size="2x" v-bind="attrs" v-on="on"></font-awesome-icon>
          </template>
          <v-carousel-item v-for="(testimonial, i) in testimonials" :key="i">
            <v-sheet height="100%">
              <v-row class="pa-10 ma-lg-10 ma-xl-10 mb-lg-2 mb-xl-2" align="center" justify="center">
                <div class="text-h6 text-md-h5 secondary--text text--center font-weight-medium">
                  {{ testimonial.quote }}
                </div>
              </v-row>
              <v-row align="center" justify="center">
                <v-avatar size="96" class="mt-7">
                  <v-img :src="testimonial.image" class="profilepic" />
                </v-avatar>
              </v-row>
              <v-row align="center" justify="center">
                <div class="outline subtitle-1 secondary--text font-weight-bold">
                  {{ testimonial.by }},&nbsp;{{ testimonial.designation }} - {{ testimonial.company }}
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-container>
      <v-row justify="center" align="center" class="section5 py-12 d-flex justify-center">
        <v-col cols="12">
          <h5 class="text-center white--text text-h4 font-weight-black">{{ cta_message }}</h5>
        </v-col>
        <v-col cols="auto">
          <GuestLoginBtn />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import GuestLoginBtn from "@/components/common/GuestLoginBtn.vue"
export default {
  name: "Testimonials",
  components: {
    GuestLoginBtn,
  },
  data() {
    return {
      section_title: "Testimonials",
      section_subtitle: "What our users say!",
      cta_message: `Learn more about how Qizto can help you excel exponentially`,
      testimonials: [
        {
          quote: `"The good thing about Quiz Labz is that they help you in evey step: preparation, organization, branding,
              technical support, and even providing the host for the event"`,
          image: require("@/assets/landing/testimonials/aditya_academy.png"),
          by: "Asif",
          designation: "Co-Founder",
          company: "Venzo Technologies",
        },
        {
          quote: `"Slide2 The good thing about Quiz Labz is that they help you in evey step: preparation, organization, branding,
              technical support, and even providing the host for the event"`,
          image: require("@/assets/landing/testimonials/aditya_academy.png"),
          by: "Balaji",
          designation: "Co-Founder",
          company: "Tinkercaps",
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
#testimonials {
  min-height: 95vh;
}
.section5 {
  // height: 40vh;
  height: auto;
  background: #ff5174;
}
</style>
