<template>
  <div class="flex-grow-1">
    <v-btn x-large block color="secondary" @click.once="anonymousSignIn" class="text-h6 font-weight-bold">
      {{ btntext }}
    </v-btn>
    <Loading :loading="loading" :text="guestlogintext" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import Loading from "@/components/common/Loading.vue"
import { guestSignIn } from "@/api/AuthApi"

export default {
  name: "GuestLoginBtn",
  props: {
    btntext: {
      type: String,
      default: () => "Get Started",
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      guestlogintext: "Creating Guest User",
      guestSignInMessage: "You are Logged in as Guest",
      icons: ["fab fa-facebook-f", "fab fa-twitter", "fab fa-instagram"],
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
    }),
  },

  methods: {
    ...mapMutations({
      showAlert: "SHOW_SNACKBAR",
    }),

    async anonymousSignIn() {
      this.loading = !this.loading
      try {
        await guestSignIn()
        this.showAlert(this.guestSignInMessage)
        this.$router.push("/dashboard")
      } catch (err) {
        this.showAlert(err.message)
      } finally {
        this.loading = !this.loading
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
