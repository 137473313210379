var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-container",
        {
          staticClass: "white mx-0 py-lg-12",
          attrs: { id: "testimonials", fluid: "", "fill-height": "" }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: "center",
                    "no-gutters": ""
                  }
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h3",
                      { staticClass: "text-center text-h4 font-weight-black" },
                      [_vm._v(_vm._s(_vm.section_title))]
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "text-h6 text-center mx-4 px-6 py-1 font-weight-medium"
                      },
                      [_vm._v(" " + _vm._s(_vm.section_subtitle) + " ")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-carousel",
                {
                  attrs: {
                    cycle: "",
                    interval: "8000",
                    height: _vm.$vuetify.breakpoint.smAndUp ? 400 : 600,
                    "hide-delimiter-background": "",
                    "show-arrows-on-hover": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prev",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "font-awesome-icon",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "arrow-circle-left",
                                    size: "2x"
                                  }
                                },
                                "font-awesome-icon",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    },
                    {
                      key: "next",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "font-awesome-icon",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "arrow-circle-right",
                                    size: "2x"
                                  }
                                },
                                "font-awesome-icon",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ])
                },
                _vm._l(_vm.testimonials, function(testimonial, i) {
                  return _c(
                    "v-carousel-item",
                    { key: i },
                    [
                      _c(
                        "v-sheet",
                        { attrs: { height: "100%" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "pa-10 ma-lg-10 ma-xl-10 mb-lg-2 mb-xl-2",
                              attrs: { align: "center", justify: "center" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-h6 text-md-h5 secondary--text text--center font-weight-medium"
                                },
                                [_vm._v(" " + _vm._s(testimonial.quote) + " ")]
                              )
                            ]
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c(
                                "v-avatar",
                                { staticClass: "mt-7", attrs: { size: "96" } },
                                [
                                  _c("v-img", {
                                    staticClass: "profilepic",
                                    attrs: { src: testimonial.image }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "outline subtitle-1 secondary--text font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(testimonial.by) +
                                      ", " +
                                      _vm._s(testimonial.designation) +
                                      " - " +
                                      _vm._s(testimonial.company) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "section5 py-12 d-flex justify-center",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "h5",
                  {
                    staticClass:
                      "text-center white--text text-h4 font-weight-black"
                  },
                  [_vm._v(_vm._s(_vm.cta_message))]
                )
              ]),
              _c("v-col", { attrs: { cols: "auto" } }, [_c("GuestLoginBtn")], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }