var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { id: "app-bar", color: "background", height: "80", app: "" } },
    [
      _c(
        "v-row",
        {
          attrs: { align: "center", justify: "space-between", "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "6", sm: "auto" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Quizzes" } } },
                [
                  _vm.user
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.logo_png,
                          height: "50",
                          "max-width": "150",
                          contain: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.user && _vm.userprofile && _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm._l(_vm.links, function(link) {
                    return _c(
                      "v-tooltip",
                      {
                        key: link.title,
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          large: "",
                                          icon: "",
                                          right: "",
                                          color: "secondary",
                                          to: {
                                            name: link.route,
                                            params: link.params
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: link.icon, size: "lg" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(link.title))])]
                    )
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        large: "",
                                        right: "",
                                        icon: "",
                                        color: "secondary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleLogout()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "door-open" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4288087552
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Signout " + _vm._s(_vm.userprofile.name))
                      ])
                    ]
                  ),
                  _c(
                    "v-avatar",
                    {
                      staticClass: "white--text mr-4",
                      attrs: { color: "secondary" }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.initials) + " "),
                      _vm.userprofile.image
                        ? _c("v-img", {
                            attrs: {
                              src:
                                "https://randomuser.me/api/portraits/women/85.jpg"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.user && _vm.userprofile && _vm.$vuetify.breakpoint.xs
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-avatar",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "secondary" }
                                      },
                                      "v-avatar",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(" " + _vm._s(_vm.initials) + " "),
                                    _vm.userprofile.image
                                      ? _c("v-img", {
                                          attrs: {
                                            src:
                                              "https://randomuser.me/api/portraits/women/85.jpg"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        879708435
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-4 secondary--text" },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "white--text",
                                  attrs: { color: "secondary" }
                                },
                                [
                                  _vm._v(" " + _vm._s(_vm.initials) + " "),
                                  _vm.userprofile.image
                                    ? _c("v-img", {
                                        attrs: {
                                          src:
                                            "https://randomuser.me/api/portraits/women/85.jpg"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-h6" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userprofile.name) + " "
                                      )
                                    ]
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(_vm.userprofile.email))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.links, function(link) {
                            return _c(
                              "v-list-item",
                              {
                                key: link.title,
                                on: {
                                  click: function($event) {
                                    _vm.$router
                                      .push({ name: link.route })
                                      .catch(function() {})
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "secondary--text" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: link.icon }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "secondary--text" },
                                      [_vm._v(_vm._s(link.title))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "door-open" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass: "secondary--text",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleLogout()
                                        }
                                      }
                                    },
                                    [_vm._v(" Signout")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }