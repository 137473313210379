<template>
  <v-app-bar id="app-bar" color="background" height="80" app elevate-on-scroll>
    <v-row class="d-flex justify-space-between align-center" no-gutters>
      <!-- LOGO and LINK TO ROOTPAGE -->

      <v-col cols="2" class="justify-center align-start">
        <router-link :to="{ name: 'Landing' }" aria-label="Home">
          <v-img :src="logo_png" height="60" contain></v-img>
        </router-link>
      </v-col>
      <!-- Home Links for larger screens -->
      <v-col cols="9" v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center justify-end">
        <router-link
          v-for="link in homelinks"
          :key="link.title"
          class="mx-4 text-h6 underline font-weight-bold secondary--text"
          text
          :to="link.route"
        >
          {{ link.title }}
        </router-link>
        <v-btn color="secondary" class="ml-2 ml-lg-4 font-weight-bold" large to="/login">
          <span>Login</span>
        </v-btn>
      </v-col>
      <!-- Home links in a menu for smaller screens -->
      <v-col cols="auto" v-if="$vuetify.breakpoint.smAndDown">
        <v-btn large class="mr-2 font-weight-bold" color="secondary" to="/login">
          <span>Login</span>
        </v-btn>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn large icon color="accent" v-bind="attrs" v-on="on">
              <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
            </v-btn>
          </template>
          <v-list class="pa-2">
            <v-list-item v-for="link in homelinks" :key="link.title">
              <router-link :to="link.route">
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import { logout } from "@/api/AuthApi"
import { mapMutations, mapState } from "vuex"
export default {
  name: "AppBar",
  data: () => ({
    dark_mode_logo: require("@/assets/common/logo_darkmode.svg"),
    light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
    logo_png: require("@/assets/common/Qizto_Logo.png"),
    homelinks: [
      { title: "How it Works", route: { name: "Landing", hash: "#getstarted" } },
      { title: "Features", route: { name: "Landing", hash: "#features" } },
      { title: "Coming Soon", route: { name: "Landing", hash: "#comingsoon" } },
      { title: "Testimonials", route: { name: "Landing", hash: "#testimonials" } },
      { title: "Contact", route: { name: "Landing", hash: "#footer" } },
    ],
    logoutSuccessMessage: "Logged Out Successfully",
    guestSignInMessage: "You are Logged in as Guest",
    loading: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
    }),
  },
  methods: {
    ...mapMutations({
      showAlert: "SHOW_SNACKBAR",
      setUser: "SET_CURRENT_USER",
      clearUserProfile: "CLEAR_USER_PROFILE_ON_SIGNOUT",
    }),

    updatePref(event) {
      this.updateDarkMode(event)
    },

    async handleLogout() {
      try {
        await logout()
        this.$router.push("/")
        this.setUser(null)
        this.clearUserProfile()
        this.showAlert(this.logoutSuccessMessage)
      } catch (err) {
        this.showAlert(err.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#app-bar {
  a {
    text-decoration: none;
  }
  .underline {
    text-decoration: none;
    &:hover {
      transition: all 0.1s ease-in;
      color: var(--v-secondary-base) !important;
      border-bottom: 4px solid var(--v-secondary-base);
    }
  }
  .router-link-active .underline {
    color: var(--v-accent-base);
    border-bottom: 4px solid var(--v-accent-base);
  }
}
</style>
