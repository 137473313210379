import { render, staticRenderFns } from "./GetStarted.vue?vue&type=template&id=561b77f3&scoped=true&"
import script from "./GetStarted.vue?vue&type=script&lang=js&"
export * from "./GetStarted.vue?vue&type=script&lang=js&"
import style0 from "./GetStarted.vue?vue&type=style&index=0&id=561b77f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561b77f3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VCol,VContainer,VImg,VRow,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/Users/asif/dev/js/qizto/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('561b77f3')) {
      api.createRecord('561b77f3', component.options)
    } else {
      api.reload('561b77f3', component.options)
    }
    module.hot.accept("./GetStarted.vue?vue&type=template&id=561b77f3&scoped=true&", function () {
      api.rerender('561b77f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/landing/GetStarted.vue"
export default component.exports