var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", id: "footer" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "Landing", hash: "#hero" },
                            "aria-label": "Home"
                          }
                        },
                        [
                          _c("v-col", [
                            _c(
                              "a",
                              { attrs: { href: "/landing" } },
                              [
                                _c("v-img", {
                                  attrs: {
                                    "max-height": "180",
                                    "max-width": "180",
                                    src: _vm.light_mode_logo
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._l(_vm.sociallinks, function(link) {
                        return _c(
                          "v-btn",
                          {
                            key: link.name,
                            staticClass: "mx-2",
                            attrs: {
                              color: "#244166",
                              icon: "",
                              large: "",
                              to: link.to,
                              "aria-label": link.name
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: link.icon, size: "2x" }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-card-text",
                        { staticClass: "caption font-weight-bold" },
                        [
                          _vm._v(
                            " " + _vm._s(new Date().getFullYear()) + " @ qizto "
                          )
                        ]
                      )
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "flex-grow-1",
                      attrs: { cols: "12", sm: "4" }
                    },
                    [
                      _c("p", { staticClass: "text-h6 font-weight-bold" }, [
                        _vm._v("Links")
                      ]),
                      _vm._l(_vm.homelinks, function(link) {
                        return _c(
                          "router-link",
                          {
                            key: link.title,
                            staticClass:
                              "subtitle-1 d-inline-flex mr-3 underline secondary--text",
                            attrs: { text: "", to: link.route }
                          },
                          [_vm._v(" " + _vm._s(link.title) + " ")]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "flex-grow-1",
                      attrs: { cols: "12", sm: "4" }
                    },
                    [
                      _c("p", { staticClass: "text-h6 font-weight-bold" }, [
                        _vm._v("Contact Us")
                      ]),
                      _c(
                        "p",
                        {
                          staticClass:
                            "subtitle-1 font-weight-medium text-justify"
                        },
                        [
                          _vm._v(" +91 9840594865"),
                          _c("br"),
                          _vm._v(" hello@quizto.com"),
                          _c("br"),
                          _vm._v(" Venzo Technologies Pvt Ltd"),
                          _c("br"),
                          _vm._v(" No.236, 8th Floor, Purva Primus,"),
                          _c("br"),
                          _vm._v(" Thoraipakkam, Chennai 600 097. ")
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }