const state = () => ({
  sessions: [],
  gameSessionListener: null,
  participantSessionListener: null,
  participantListListener: null,
  participantSession: {
    name: "",
    color: "",
    index: null,
    futuretime: 0,
    lastselected: {},
    details: [],
    showanswer: false,
    totalscore: 0,
    powerupcount: 0,
    quizid: "",
    sessionid: "",
    participantsessionid: "",
    end: false,
  },
  participantList: [],
  gameSession: {
    quizid: null,
    createdAt: null,
    index: null,
    end: false,
    started: false,
    enablequestion: true,
    futuretime: 0,
    quizpin: null,
    showanswer: false,
    showleader: false,
    sessionname: "",
    type: "",
    advanced: null,
    questions: [],
    timer: null,
  },
})

const getters = {
  getLeaders: (state) => {
    return state.participantList
      .filter((participant) => participant.details?.length && participant.totalscore !== 0)
      .concat()
      .sort((a, b) => b.totalscore - a.totalscore || 0)
      .slice(0, 5)
  },

  getCurrentQuizQuestion: (state) => {
    if (state.gameSession.type === "L") {
      return state.gameSession?.questions[state.gameSession.index] ?? {}
    } else {
      return state.gameSession?.questions[state.participantSession.index] ?? {}
    }
  },

  getFastestAnswerer: (state) => {
    return state.participantList
      .filter((a) => a.lastselected.isRight)
      .concat()
      .sort((a, b) => a.lastselected.elapsedtime - b.lastselected.elapsedtime || 0)[0]
  },
}

const mutations = {
  SET_GAME_SESSION(state, gameSession) {
    Object.assign(state.gameSession, gameSession)
  },

  SET_PARTICIPANT_SESSION(state, participantSession) {
    Object.assign(state.participantSession, participantSession)
  },

  SET_PARTICIPANT_LIST(state, participantList) {
    state.participantList = participantList
  },

  ADD_PARTICIPANT_ANSWER(state, answer) {
    state.participantSession.details.push(answer)
  },

  CLEAR_GAME_DATA(state) {
    state.participantList = []
    Object.assign(state.gameSession, {})
    Object.assign(state.participantSession, {})
    if (state.gameSessionListener) {
      state.gameSessionListener()
    }
    if (state.participantSessionListener) {
      state.participantListListener()
    }
    if (state.participantSessionListener) {
      state.participantSessionListener()
    }
  },

  SET_PARTICIPANT_LIST_LISTENER(state, payload) {
    state.participantListListener = payload
  },

  SET_PARTICIPANT_SESSION_LISTENER(state, payload) {
    state.participantSessionListener = payload
  },

  SET_GAME_SESSION_LISTENER(state, payload) {
    state.gameSessionListener = payload
  },
}

const actions = {
  bindParticipantList: ({ commit }, result) => {
    commit("SET_PARTICIPANT_LIST", result)
  },

  bindGameSession: ({ commit }, session) => {
    commit("SET_GAME_SESSION", session)
  },

  bindParticipantSession: ({ commit }, session) => {
    commit("SET_PARTICIPANT_SESSION", session)
  },

  addParticipantAnswer: ({ commit }, answer) => {
    commit("ADD_PARTICIPANT_ANSWER", answer)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
