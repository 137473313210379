var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rotating-image-container" },
    [
      _c("v-img", {
        staticClass: "rotating-image",
        attrs: {
          src: require("@/assets/landing/hero/Background_Circle.svg"),
          "max-width": _vm.dimension,
          "max-height": _vm.dimension,
          contain: ""
        }
      }),
      _c("v-img", {
        staticClass: "fixed-image",
        attrs: {
          "lazy-src": require("@/assets/landing/hero/Circle_With_Mockup.svg"),
          src: require("@/assets/landing/hero/Circle_With_Mockup_new.svg"),
          "max-width": _vm.dimension,
          "max-height": _vm.dimension,
          contain: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }