import { library } from "@fortawesome/fontawesome-svg-core" // Core SVG
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBolt,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClipboardList,
  faClock,
  faCog,
  faCompress,
  faDiceD20,
  faDoorOpen,
  faEdit,
  faEnvelopesBulk,
  faEraser,
  faExpand,
  faEye,
  faEyeSlash,
  faFileContract,
  faForward,
  faHeart,
  faHome,
  faIdCard,
  faImage,
  faInfoCircle,
  faMagnifyingGlass,
  faMinus,
  faPen,
  faPlay,
  faPlayCircle,
  faPlus,
  faPoll,
  faQuestionCircle,
  faSave,
  faSignInAlt,
  faSquare,
  faSquarePlus,
  faStar,
  faStarHalfAlt,
  faStop,
  faStopCircle,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUndo,
  faUpload,
  faUser,
  faUserCircle,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome" // Integration
library.add(
  faPlus,
  faBell,
  faQuestionCircle,
  faBars,
  faExpand,
  faSignInAlt,
  faSquarePlus,
  faUserCircle,
  faUserPlus,
  faForward,
  faStop,
  faIdCard,
  faDoorOpen,
  faInfoCircle,
  faUser,
  faStar,
  faBolt,
  faEraser,
  faArrowRight,
  faArrowLeft,
  faStarHalfAlt,
  faStopCircle,
  faTimesCircle,
  faPlayCircle,
  faPlay,
  faArrowCircleLeft,
  faArrowCircleRight,
  faUpload,
  faTimes,
  faClock,
  faImage,
  faPoll,
  faCheck,
  faMinus,
  faSave,
  faUndo,
  faTimesCircle,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCog,
  faEdit,
  faPen,
  faHeart,
  faCompress,
  faClipboardList,
  faTrashAlt,
  faHome,
  faFacebookF,
  faTwitter,
  faInstagram,
  faSquare,
  faCheckSquare,
  faCircle,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faEnvelopesBulk,
  faDiceD20,
  faFileContract,
  faMagnifyingGlass
)
const CUSTOM_ICONS = {
  checkboxOff: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-square"],
    },
  },
  checkboxOn: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-check-square"],
    },
  },
  radioOff: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-circle"],
    },
  },
  radioOn: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-check-circle"],
    },
  },
  unmaskPassword: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-eye"],
    },
  },
  maskPassword: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-eye-slash"],
    },
  },
  clear: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "fa-times-circle"],
    },
  },
}

export { CUSTOM_ICONS }
