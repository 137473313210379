import { updateQuiz } from "@/api/QuizApi"
import Vue from "vue"

const state = () => ({
  quizListListener: null,
  quizListener: null,
  sessionsListener: null,
  gameSessions: [],
  participantSessions: [],
  sessiondata: [],
  currentSession: {},
  quizzes: [],
  currentQuiz: {},
  currentQuestion: {},
  currentQuizId: null,
  currentQuestionId: null,
  newQuestion: {
    question: "",
    hint: "",
    timer: 30,
    points: 30,
    answertype: "M",
    imagelink: "",
    fileName: "",
    score: 0,
    selectedanswer: "",
    chosenanswer: "No Answer",
    elapsedtime: "",
    correctanswers: [false, false],
    answers: ["", ""],
  },
  newQuiz: {
    title: "",
    description: "",
    timerenabled: true,
    timer: 30,
    points: 30,
    questions: [],
    advanced: false,
    lifelines: [],
    lifelinefrequency: 40,
    maxlifelines: 4,
    randomlifeline: false,
    reducepercent: 50,
    reducescore: true,
    defaultquestiontype: "M",
    type: "L",
  },
  alertmsgs: {
    quizsaved: "Quiz Saved",
    quizupdated: "Quiz Updated",
    quizadded: "Quiz Added",
    unexpected:
      "Oops..I did not expect this to happen. Please Try Again Hopefully it will be alright. If issue persists please write to support@qizto.com",
  },
})

const getters = {
  getQuizById: (state) => {
    return function (id) {
      return state.quizzes.filter((item) => {
        return item.quizid === id
      })[0]
    }
  },

  getSessionById: (state) => {
    return function (id) {
      return state.participantSessions.filter((item) => {
        return item.participantsessionid === id
      })[0]
    }
  },
}

const mutations = {
  SET_CURRENT_SESSION(state, payload) {
    state.currentSession = { ...payload }
  },
  SET_CURRENT_QUESTION_ID(state, id) {
    state.currentQuestionId = id
  },

  SET_CURRENT_QUESTION_FROM_QUIZ(state, payload) {
    state.currentQuestion = { ...payload }
  },

  SET_CURRENT_QUESTION(state, payload) {
    state.currentQuestion = { ...payload }
  },

  UPDATE_CURRENT_QUESTION(state, payload) {
    Object.assign(state.currentQuestion, payload)
  },

  SET_CURRENT_QUIZ_ID(state, id) {
    state.currentQuizId = id
  },

  SET_CURRENT_QUIZ(state, payload) {
    state.currentQuiz = { ...payload }
  },

  UPDATE_CURRENT_QUIZ(state, payload) {
    Object.assign(state.currentQuiz, payload)
  },

  ADD_QUESTION_TO_QUIZ(state) {
    state.currentQuiz.questions.push(state.currentQuestion)
  },

  UPDATE_QUESTION_TO_QUIZ(state) {
    Vue.set(state.currentQuiz.questions, state.currentQuestionId, state.currentQuestion)
  },

  DELETE_CURRENT_QUESTION_FROM_QUIZ(state) {
    state.currentQuiz.questions.splice(state.currentQuestionId, 1)
  },

  UPDATE_QUESTION_ORDER(state, updatedlist) {
    state.currentQuiz.questions = updatedlist
  },

  SET_QUIZZES(state, quizzes) {
    state.quizzes = quizzes
  },

  SET_GAME_SESSIONS(state, sessions) {
    state.gameSessions = sessions
  },

  SET_PARTICIPANT_SESSIONS(state, sessions) {
    state.participantSessions = sessions
  },

  SET_SESSIONS_LISTENER(state, payload) {
    state.sessionsListener = payload
  },

  SET_QUIZ_LISTENER(state, payload) {
    state.quizListener = payload
  },

  SET_QUIZ_LIST_LISTENER(state, payload) {
    state.quizListListener = payload
  },

  CLEAR_SESSIONS_DATA(state) {
    state.gameSessions = []
    state.participantSessions = []
    state.sessionsListener()
  },
}

const actions = {
  setSession: ({ commit }, session) => {
    commit("SET_CURRENT_SESSION", session)
  },
  setQuizzes: ({ commit }, quizzes) => {
    commit("SET_QUIZZES", quizzes)
  },

  setGameSessions: ({ commit }, gameSessions) => {
    commit("SET_GAME_SESSIONS", gameSessions)
  },

  setParticipantSessions: ({ commit }, participantSessions) => {
    commit("SET_PARTICIPANT_SESSIONS", participantSessions)
  },

  setCurrentQuiz: async ({ commit }, quiz) => {
    if (quiz) {
      commit("SET_CURRENT_QUIZ", quiz)
      commit("SET_CURRENT_QUIZ_ID", quiz.id)
    } else {
      commit("SET_CURRENT_QUIZ", {})
      commit("SET_CURRENT_QUIZ_ID")
      commit("SET_CURRENT_QUESTION_ID")
    }
  },

  setNewQuiz: ({ state, commit }, value) => {
    const questiontype = value === "S" ? "P" : "M"
    const timerenabled = value === "S" ? false : true
    commit("SET_CURRENT_QUIZ", {
      ...state.newQuiz,
      type: value,
      defaultquestiontype: questiontype,
      timerenabled: timerenabled,
    })
  },

  updateCurrentQuiz: async ({ dispatch, commit }, quiz) => {
    commit("SET_CURRENT_QUIZ", quiz)
    dispatch("updateQuizToFirebase")
  },

  updateQuestionOrder: async ({ dispatch, commit }, questions) => {
    commit("UPDATE_QUESTION_ORDER", questions)
    dispatch("updateQuizToFirebase")
  },

  updateQuiz: ({ commit }, value) => {
    commit("UPDATE_CURRENT_QUIZ", value)
  },

  setCurrentQuestion: ({ state, commit }, id) => {
    if (id >= 0) {
      commit("SET_CURRENT_QUESTION_ID", id)
      commit("SET_CURRENT_QUESTION", state.currentQuiz.questions[id])
    } else {
      commit("SET_CURRENT_QUESTION_ID")
      commit("SET_CURRENT_QUESTION", state.newQuestion)
    }
  },

  setCurrentQuestionFromQuiz: ({ commit }, question) => {
    commit("SET_CURRENT_QUESTION_ID")
    commit("SET_CURRENT_QUESTION_FROM_QUIZ", question)
  },

  clearCurrentQuestion: ({ commit }) => {
    commit("SET_CURRENT_QUESTION_ID")
    commit("SET_CURRENT_QUESTION", {})
  },

  updateCurrentQuestion: ({ commit }, value) => {
    commit("UPDATE_CURRENT_QUESTION", value)
  },

  addOrUpdateQuestionInQuiz: async ({ dispatch, state, commit }) => {
    if (state.currentQuestionId >= 0) {
      commit("UPDATE_QUESTION_TO_QUIZ")
    } else {
      commit("ADD_QUESTION_TO_QUIZ")
    }
    dispatch("updateQuizToFirebase")
  },

  deleteQuestionInQuiz: async ({ dispatch, state, commit }) => {
    commit("DELETE_CURRENT_QUESTION_FROM_QUIZ")

    state.currentQuestionId >= 0
      ? commit("SET_CURRENT_QUESTION_ID", state.currentQuestionId - 1)
      : commit("SET_CURRENT_QUESTION_ID")
    dispatch("updateQuizToFirebase")
  },

  updateQuizToFirebase: async ({ state }) => {
    await updateQuiz(state.currentQuiz)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
