<template>
  <v-container fluid class="pa-0 ma-0" v-once>
    <Hero />
    <GetStarted />
    <Features />
    <ComingSoon />
    <Testimonials />
    <Footer />
  </v-container>
</template>

<script>
import Hero from "@/components/landing/Hero.vue"
import GetStarted from "@/components/landing/GetStarted.vue"
import Features from "@/components/landing/Features.vue"
import ComingSoon from "@/components/landing/ComingSoon.vue"
import Testimonials from "@/components/landing/Testimonials.vue"
import Footer from "@/components/landing/Footer.vue"
export default {
  components: {
    Hero,
    GetStarted,
    Features,
    ComingSoon,
    Testimonials,
    Footer,
  },
  metaInfo: {
    title: "User Engagement Platform to make Quizzes & Polls | Free Online Quiz Maker",
    titleTemplate: "Qizto | %s ",
    meta: [
      {
        name: "description",
        content:
          "Qizto creates simple solutions that make teaching and learning easy for everyone, from the youngest students to faculty and corporate leaders.",
      },
    ],
  },
}
</script>
<style lang="scss" scoped>
.paragraph {
  color: black;
  font-size: 1rem;
  line-height: 2rem;
}
.v-btn {
  text-transform: none;
}
</style>
